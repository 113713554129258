import ServiceIcon from '@material-ui/icons/BorderClear';
import SearchPageIcon from '@material-ui/icons/Search';
import locales from './locales';

import actionType from './resources/actionType';
import aggrDocType from './resources/aggrDocType';
import bufferStatus from './resources/bufferStatus';
import cancelReason from './resources/cancelReason';
import codeType from './resources/codeType';
import confirmDocType from './resources/confirmDocType';
import controlSamplesType from './resources/controlSamplesType';
import deliveryType from './resources/deliveryType';
import directionType from './resources/directionType';
import docStatus from './resources/docStatus';
import docLink from './resources/docLink';
import docLinkType from './resources/docLinkType';
import docStatusSystem from './resources/docStatusSystem';
import emissionType from './resources/emissionType';
import errorModel from './resources/errorModel';
import good from './resources/good';
import labelTemplate from './resources/labelTemplate';
import markingStatus from './resources/markingStatus';
import markingType from './resources/markingType';
import mrkStatusSystem from './resources/mrkStatusSystem';
import objectSendModel from './resources/objectSendModel';
import orderCheckKM from './resources/orderCheckKM';
import orderCloseOrder from './resources/orderCloseOrder';
import orderGetKM from './resources/orderGetKM';
import orderH from './resources/orderH';
import orderM from './resources/orderM';
import orderP from './resources/orderP';
import orderSend from './resources/orderSend';
import ordersPool from './resources/ordersPool';
import orderStatus from './resources/orderStatus';
import orderType from './resources/orderType';
import orderUpdateAllStatuses from './resources/orderUpdateAllStatuses';
import orderUpload from './resources/orderUpload';
import printJasper from './resources/printJasper';
import productionType from './resources/productionType';
import productionTypeInput from './resources/productionTypeInput';
import printJournal from './resources/printJournal';
import printJournalElement from './resources/printJournalElement';
import printUpload from './resources/printUpload';
import printTicketUpload from './resources/printTicketUpload';
import printLoad from './resources/printLoad';
import queryRequest from './resources/queryRequest';
import quickPrint from './resources/quickPrint';
import requestType from './resources/requestType';
import releaseMethodType from './resources/releaseMethodType';
import requestStatus from './resources/requestStatus';
import serialNumType from './resources/serialNumType';
import serialNumberType from './resources/serialNumberType';
import treeMrk from './resources/treeMrk';
import turnoverType from './resources/turnoverType';
import treeMrkType from './resources/treeMrkType';
import treeMrkAggregation from './resources/treeMrkAggregation';
import treeMrkDisaggregation from './resources/treeMrkDisaggregation';
import treeMrkReaggregation from './resources/treeMrkReaggregation';
import xmlRequestDocument from './resources/xmlRequestDocument';
import xmlRequestTicket from './resources/xmlRequestTicket';
import treeMrkLoadCustom from './resources/treeMrkLoadCustom';
import goodUpload from './resources/goodUpload';
import notificationStatusChange from './resources/notificationStatusChange';
import mcMovementLogs from './resources/mcMovementLogs';
import mrkMoveLogs from './resources/mrkMoveLogs';
import rePrintByXlsx from './resources/rePrintByXlsx';
import taskManager from './resources/taskManager';
import taskManagerErrors from './resources/taskManagerErrors';
import taskManagerLogs from './resources/taskManagerLogs';
import ismpDocumentStatus from './resources/ismpDocumentStatus';
import mrkPackageType from './resources/mrkPackageType';
import mrkPacksType from './resources/mrkPacksType';
import mrkTreeType from './resources/mrkTreeType';
import mrkExStatus from './resources/mrkExStatus';
import taskManagerTask from './resources/taskManagerTask';
import orderOmsStatus from './resources/orderOmsStatus';
import funcType from './resources/funcType';
import cisPackageType from './resources/cisPackageType';
import withdrawalType from './resources/withdrawalType';
import xlsBrokerTemplateEnum from './resources/xlsBrokerTemplateEnum';
import importThirdCountryH from './resources/importThirdCountryH';
import importThirdCountryP from './resources/importThirdCountryP';
import importThirdCountryM from './resources/importThirdCountryM';
import importHImport from './resources/importHImport';
import docCheckStatus from './resources/docCheckStatus';

import outH from './resources/outH';
import outP from './resources/outP';
import outHImport from './resources/outHImport';
import treeMrkkz from './resources/treeMrkKz';
const resources = [
  good,
  orderH,
  orderM,
  orderP,

  treeMrkkz,

  importThirdCountryH,
  importThirdCountryP,
  importThirdCountryM,
  importHImport,

  outH,
  outP,
  outHImport,
  // treeMrk,
  //отгрузка / приемка
  printJournal,
  printJournalElement,

  // далее уже журналы идут

  errorModel,
  objectSendModel,

  actionType,
  aggrDocType,
  bufferStatus,
  cancelReason,
  codeType,
  confirmDocType,
  controlSamplesType,
  deliveryType,
  directionType,
  docStatus,
  docLink,
  docLinkType,
  docStatusSystem,
  emissionType,
  labelTemplate,
  markingStatus,
  markingType,
  mrkStatusSystem,
  orderCheckKM,
  orderCloseOrder,
  orderGetKM,
  orderSend,
  ordersPool,
  orderStatus,
  orderOmsStatus,
  orderType,
  orderUpdateAllStatuses,
  orderUpload,
  funcType,
  withdrawalType,

  printJasper,
  productionType,
  productionTypeInput,
  printUpload,
  printTicketUpload,
  printLoad,
  queryRequest,
  quickPrint,
  requestType,
  releaseMethodType,
  requestStatus,
  serialNumType,
  serialNumberType,
  turnoverType,
  treeMrkType,
  treeMrkAggregation,
  treeMrkDisaggregation,
  treeMrkReaggregation,
  xmlRequestDocument,
  xmlRequestTicket,

  treeMrkLoadCustom,

  goodUpload,
  notificationStatusChange,
  mcMovementLogs,
  mrkMoveLogs,
  rePrintByXlsx,
  mrkPackageType,
  mrkPacksType,
  mrkTreeType,
  mrkExStatus,

  taskManager,
  taskManagerErrors,
  taskManagerLogs,
  ismpDocumentStatus,
  taskManagerTask,
  cisPackageType,
  xlsBrokerTemplateEnum,
  docCheckStatus,
];

export default {
  name: 'marking',
  locales,
  resources,
  icon: ServiceIcon,
  customMenuItems: [
    {
      path: '/search-km',
      icon: SearchPageIcon,
      name: 'searchKMPage.title',
    },
  ],
  documentation: {
    swaggerResourcesPath: '/swagger-resources',
  },
};
