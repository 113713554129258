import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import download from 'downloadjs';
import {
  translate,
  SimpleForm,
  showNotification,
  TextInput,
  NumberInput,
  SelectInput,
  Toolbar,
  SaveButton,
  BooleanInput,
  RequiredTextInput,
  required,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import compose from 'recompose/compose';
import qs from 'qs';
import { withRouter } from 'react-router';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import resourceConfig from './config';
import request from '../../../../network/request';
import { paramToDictionary, asyncTimeout } from '../../../../utils';
import { restProviderHandle } from '../../../../network/rest';
import emissionType from '../emissionType';
import mrkStatusSystem from '../mrkStatusSystem';
import printPDF from '../../../../utils/printPDF';
import { TrafficRounded } from '@material-ui/icons';

const styles = {
  head: {
    display: 'flex',
  },
  label: { width: '10em', display: 'inline-block' },
  toolbar: { background: 'none' },
  buttonsContainer: {
    margin: '20px 0 30px',
    display: 'flex',
  },
  button: { marginRight: 10 },
  loader: {
    marginLeft: 20,
  },
  card: {
    margin: '20px 0 0',
  },
};

const FormToolbar = translate(({ translate, disabled, createAnother, ...props }) => (
  <Toolbar style={styles.toolbar} {...props}>
    <SaveButton
      label={translate(`resources.${resourceConfig.name}.executeAction`)}
      redirect={false}
      submitOnEnter={true}
      style={styles.button}
      disabled={disabled}
    />
    <Button type={'button'} onClick={createAnother}>
      {translate(`resources.${resourceConfig.name}.createAnotherAction`)}
    </Button>
    {props.saving && (
      <div style={styles.loader}>
        <CircularProgress />
      </div>
    )}
  </Toolbar>
));

class AppModule extends Component {
  defaultParams = {
    _fileType: 'pdf',
    menge: 1,
    importPrint: true,
  };

  responseAction = 'print'; // download
  lastFormData = {};
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      executing: false,
      searchingGoods: false,
      existGoods: null,
      searchingPrintJournalElement: false,
      existPrintJournalElement: null,
      processing: false,
      formId: 1,
      templateNames: [],
      serialNumberEmpty: true,
      gtinEmpty: true,
      params: {
        ...this.defaultParams,
      },
    };
  }

  componentDidMount = () => {
    this.checkLocation();
    this.loadTemplates();
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.location.search !== prevProps.location.search) {
      this.checkLocation();
    }
  };

  checkLocation = () => {
    if (this.props.location.search.length < 2) {
      this.resetState();
      return;
    }
    const params = qs.parse(this.props.location.search.slice(1));
    if (!(resourceConfig.urlParamName in params)) {
      this.resetState();
      return;
    }
    if (this.state.show) {
      return;
    }
    const data = paramToDictionary(params[resourceConfig.urlParamName]);

    this.setState({
      show: true,
      params: {
        ...this.defaultParams,
        ...data,
      },
    });
  };

  resetState = () => {
    this.lastFormData = {};
    this.searchGoodsTimeout = null;
    this.searchGoodsWaiting = null;
    this.searchPrintJournalElementTimeout = null;
    this.searchPrintJournalElementWaiting = null;
    this.createdPrintJournal = null;
    this.setState({
      show: false,
      executing: false,
      searchingGoods: false,
      existGoods: null,
      searchingPrintJournalElement: false,
      existPrintJournal: null,
      existPrintJournalElement: null,
      processing: false,
      serialNumberEmpty: true,
      gtinEmpty: true,
    });
    setTimeout(this.afterClose, 500);
  };

  afterClose = () => {
    this.setState({
      params: {
        ...this.defaultParams,
      },
    });
  };

  close = () => {
    if (this.state.processing) {
      return;
    }
    this.props.history.goBack();
  };

  get fileType() {
    return this.lastFormData._fileType || this.state.params._fileType;
  }

  searchGoodsTimeout = null;
  searchGoodsWaiting = null;
  searchGoods = () => {
    if (this.state.searchingGoods) {
      this.searchGoodsWaiting = true;
      return;
    }

    clearTimeout(this.searchGoodsTimeout);
    if (!this.lastFormData.matnr) {
      this.setState({
        existGoods: null,
      });
      return;
    }

    this.setState({
      searchingGoods: true,
    });

    this.searchGoodsTimeout = setTimeout(async () => {
      const items = await restProviderHandle('GET_MANY_REFERENCE', 'marking--goods', {
        filter: {
          numgoods: this.lastFormData.matnr,
        },
      });

      let result = null;
      if (items.data.length > 0) {
        result = items.data.filter((x) => x.gtin && x.numgoods === this.lastFormData.matnr);
        // result = items.data;
      }

      this.setState(
        {
          searchingGoods: false,
          existGoods: result,
        },
        () => {
          if (this.searchGoodsWaiting) {
            this.searchGoodsWaiting = false;
            this.searchGoods();
          }
        },
      );
    }, 300);
  };

  loadTemplates = async () => {
    const templates = await restProviderHandle('GET_LIST', 'marking--labelTemplate', {
      pagination: { perPage: 200, page: 1 },
    });

    if (templates.data) {
      this.setState({
        templateNames: templates.data,
      });
    }
  };

  searchPrintJournalElementTimeout = null;
  searchPrintJournalElementWaiting = null;
  searchPrintJournalElement = () => {
    if (this.state.searchingPrintJournalElement) {
      this.searchPrintJournalElementWaiting = true;
      return;
    }

    clearTimeout(this.searchPrintJournalElementTimeout);
    if (!this.lastFormData.gtin || !this.lastFormData.serialNumber) {
      this.setState({
        existPrintJournalElement: null,
      });
      return;
    }

    this.setState({
      searchingPrintJournalElement: true,
    });

    this.searchPrintJournalElementTimeout = setTimeout(async () => {
      const items = await restProviderHandle('GET_MANY_REFERENCE', 'marking--printJournalElement', {
        filter: {
          gtin: this.lastFormData.gtin,
          serialNumber: this.lastFormData.serialNumber,
        },
      });

      let result = null;
      let element = null;
      if (items.data.length > 0) {
        element =
          items.data.find(
            (x) =>
              x.gtin === this.lastFormData.gtin &&
              x.serialNumber === this.lastFormData.serialNumber,
          ) || null;
        if (element !== null) {
          try {
            const printJournalItem = await restProviderHandle('GET_ONE', 'marking--printJournal', {
              id: element.printJournalId,
            });
            if (printJournalItem && printJournalItem.data) {
              result = printJournalItem.data;
            }
          } catch (e) {}
        }
      }

      this.setState(
        {
          searchingPrintJournalElement: false,
          existPrintJournalElement: result,
        },
        () => {
          if (this.searchPrintJournalElementWaiting) {
            this.searchPrintJournalElementWaiting = false;
            this.searchPrintJournalElement();
          }
        },
      );
    }, 300);
  };

  loadPrintJournal = async (id) => {
    const printJournal = await restProviderHandle('GET_ONE', 'marking--printJournal', { id });
    if (!printJournal.data) {
      throw new Error(`Print journal #${id} not found`);
    }
    if (this.createdPrintJournal !== null && this.createdPrintJournal.id === id) {
      this.createdPrintJournal = printJournal.data;
    }

    return printJournal.data;
  };

  loadPrintJournalElement = async (id) => {
    const printJournalElement = await restProviderHandle(
      'GET_ONE',
      'marking--printJournalElement',
      { id },
    );
    if (!printJournalElement.data) {
      throw new Error(`Print journal element #${id} not found`);
    }
    if (
      this.state.existPrintJournalElement !== null &&
      this.state.existPrintJournalElement.id === id
    ) {
      this.setState({
        existPrintJournalElement: printJournalElement.data,
      });
    }

    return printJournalElement.data;
  };

  getErrorMessageFromResponse = (response) => {
    const { translate } = this.props;
    let message = 'Unknown error';
    if (!response || !response.data || !response.data.message) {
      return message;
    }
    const serverMessage = response.data.message;

    if (serverMessage.includes('No found eanInfo or gtinValue')) {
      message = translate(`resources.${resourceConfig.name}.canNotFoundEANOrGTIN`);
    } else if (serverMessage.includes('The product is not registered in the declaration')) {
      message = translate(
        `resources.${resourceConfig.name}.theProductIsNotRegisteredInTheDeclaration`,
      );
    } else if (serverMessage.includes('No found article map by itemCode')) {
      message = translate(`resources.${resourceConfig.name}.notFoundArticleMapByItemCode`);
    } else if (serverMessage.includes('Bad query. Set gtin or ordernum')) {
      message = translate(`resources.${resourceConfig.name}.setGTINOrOrdernum`);
    } else if (serverMessage.includes('Bad query')) {
      message = translate(`resources.${resourceConfig.name}.incorrectLabelTemplate`);
    }

    return message;
  };

  createdPrintJournal = null;
  createPrintJournal = async () => {
    let type = 'CREATE';
    let params = {
      data: this.lastFormData,
    };
    if (this.lastFormData.importPrint) {
      // TODO: переделать на изменение формы при выборе чекбокса. Временный фикс
      params.data.emissionType = 'FOREIGN';
    }
    if (this.createdPrintJournal !== null) {
      type = 'UPDATE';
      params.id = this.createdPrintJournal.id;
      params.data = {
        ...this.createdPrintJournal,
        ...params.data,
      };
    }
    const printJournal = await restProviderHandle(type, 'marking--printJournal', params);

    if (!printJournal.data) {
      throw new Error(`Error when "${type}" print journal`);
    }

    this.createdPrintJournal = printJournal.data;
    return this.createdPrintJournal;
  };

  createFile = async (printJournal, type) => {
    let data = {
      id: printJournal.id,
    };
    const response = await request({
      apiService: 'shoes',
      path: `/admin/print/func/${type}`,
      urlParams: data,
      method: 'POST',
    });

    if (!response.ok) {
      throw new Error(this.getErrorMessageFromResponse(response));
    }

    return response.data;
  };

  downloadFile = async (printJournal, type) => {
    let data = {
      path: '',
    };
    if (type === 'pdf' && printJournal.pdfPath) {
      data.path = printJournal.pdfPath;
    } else if (type === 'csv' && printJournal.csvPath) {
      data.path = printJournal.csvPath;
    }

    var timesTried = 0;
    var response = await request({
      apiService: 'shoes',
      path: '/admin/print/func/load',
      urlParams: data,
      method: 'GET',
      responseType: 'blob',
    });

    if (!response.ok) {
      throw new Error(this.getErrorMessageFromResponse(response));
    }

    const blob = response.data;
    if (this.responseAction === 'print') {
      printPDF(blob);
    } else if (this.responseAction === 'download') {
      let fileName = 'load';
      let mimeType = null;
      if (data.path) {
        const pathParts = data.path.split('/');
        fileName = pathParts[pathParts.length - 1];
      }
      download(blob, fileName, mimeType);
    }

    return {};
  };

  getFile = async (fileType, useJournalElement = false) => {
    const { translate } = this.props;
    this.setState({
      processing: true,
    });
    try {
      let document = null;

      if (useJournalElement && this.state.existPrintJournalElement !== null) {
        document = this.state.existPrintJournalElement;
      } else if (useJournalElement) {
        throw new Error();
      } else {
        document = await this.createPrintJournal();
        await asyncTimeout(1000);
      }

      if (document === null) {
        throw new Error();
      }

      let path = null;
      if (fileType === 'pdf' && document.pdfPath) {
        path = document.pdfPath;
      } else if (fileType === 'csv' && document.csvPath) {
        path = document.csvPath;
      }

      if (path === null) {
        const fileCreated = await this.createFile(document, fileType);
        const quantity = document.menge || 1;
        if (fileCreated.length === 0) {
          throw new Error(translate(`resources.${resourceConfig.name}.errorMessageKMNotFound`));
        } else if (fileCreated.length < quantity) {
          this.props.showNotification(
            translate(`resources.${resourceConfig.name}.warnFoundKMNotAll`, {
              found: fileCreated.length,
              total: quantity,
            }),
            'warning',
          );
        }

        await asyncTimeout(3000);

        if (useJournalElement) {
          document = await this.loadPrintJournalElement(document.id);
        } else {
          document = await this.loadPrintJournal(document.id);
          var retryCount = 0;
          while (document.pdfPath == null && retryCount < 200) {
            await asyncTimeout(3000);
            document = await this.loadPrintJournal(document.id);
            retryCount++;
            console.log(retryCount);
          }
        }
      }

      await this.downloadFile(document, fileType);
    } catch (e) {
      console.log(e);
      const message = e.message || `resources.${resourceConfig.name}.errorMessage`;
      this.props.showNotification(message, 'error');
    }
    this.setState({
      processing: false,
    });
  };

  createAnother = () => {
    this.setState(
      (state) => ({
        executing: false,
        searchingGoods: false,
        existGoods: null,
        searchingPrintJournalElement: false,
        existPrintJournalElement: null,
        processing: false,
        formId: state.formId + 1,
        params: {
          ...state.params,
          ...this.lastFormData,
          matnr: this.defaultParams.matnr || null,
          gtin: this.defaultParams.gtin || null,
          ean: this.defaultParams.ean || null,
          serialNumber: this.defaultParams.serialNumber || null,
        },
      }),
      () => {
        this.lastFormData = {};
        this.searchGoodsTimeout = null;
        this.searchGoodsWaiting = null;
        this.searchPrintJournalElementTimeout = null;
        this.searchPrintJournalElementWaiting = null;
        this.createdPrintJournal = null;
      },
    );
  };

  onSubmitNew = (formData) => {
    this.lastFormData = formData;
    this.getFile(this.fileType);
  };
  onSubmitExist = () => {
    this.getFile(this.fileType, true);
  };

  validate = (formData) => {
    // Using validate as onChange
    const lastFormData = this.lastFormData;
    this.lastFormData = formData;

    if (formData.matnr !== lastFormData.matnr) {
      this.searchGoods();
    } else if (
      formData.gtin !== lastFormData.gtin ||
      formData.serialNumber !== lastFormData.serialNumber
    ) {
      this.searchPrintJournalElement();
    }

    const serialNumberEmpty = !formData.serialNumber;
    const gtinEmpty = !formData.gtin;

    if (this.state.serialNumberEmpty !== serialNumberEmpty || this.state.gtinEmpty !== gtinEmpty) {
      this.setState({
        serialNumberEmpty,
        gtinEmpty,
      });
    }

    return {};
  };

  validateEan(val) {
    console.log(val);
    return false;
  }

  render() {
    const { translate } = this.props;
    const {
      templateNames,
      serialNumberEmpty,
      gtinEmpty,
      searchingPrintJournalElement,
      existPrintJournalElement,
    } = this.state;
    const hasExistGoods = this.state.existGoods !== null && this.state.existGoods.length > 0;

    return (
      <Drawer anchor={'right'} open={this.state.show} onClose={this.close}>
        <div key={this.state.formId}>
          <SimpleForm
            defaultValue={this.state.params}
            saving={this.state.processing}
            save={this.onSubmitNew}
            toolbar={
              <FormToolbar createAnother={this.createAnother} disabled={!serialNumberEmpty} />
            }
            validate={this.validate}
            style={{ width: 600 }}>
            <div style={styles.head}>
              <Typography fullWidth variant={'title'}>
                {translate(`resources.${resourceConfig.name}.title`)}
              </Typography>
              {(this.state.searchingGoods || this.state.searchingPrintJournalElement) && (
                <div style={styles.loader}>
                  <CircularProgress size={20} />
                </div>
              )}
            </div>

            <SelectInput
              fullWidth
              label={translate(`resources.${resourceConfig.name}.downloadAs`)}
              source={'_fileType'}
              choices={[
                {
                  id: 'pdf',
                  name: translate(`resources.${resourceConfig.name}.asPdf`),
                },
                {
                  id: 'csv',
                  name: translate(`resources.${resourceConfig.name}.asCsv`),
                },
              ]}
            />

            <TextInput
              source={'matnr'}
              fullWidth
              label={translate(`resources.${resourceConfig.name}.fields.matnr`)}
            />

            {hasExistGoods && (
              <Typography fullWidth>
                {translate(`resources.${resourceConfig.name}.findCount`, {
                  count: this.state.existGoods.length,
                })}
              </Typography>
            )}

            {hasExistGoods ? (
              <SelectInput
                fullWidth
                source={'gtin'}
                choices={this.state.existGoods}
                optionText="gtin"
                optionValue="gtin"
                label={translate(`resources.${resourceConfig.name}.fields.gtin`)}
              />
            ) : (
              <TextInput
                source={'gtin'}
                fullWidth
                label={translate(`resources.${resourceConfig.name}.fields.gtin`)}
              />
            )}
            <TextInput
              source={'serialNumber'}
              fullWidth
              label={translate(`resources.${resourceConfig.name}.fields.serialNumber`)}
            />

            {!serialNumberEmpty && (
              <Card style={styles.card} fullWidth>
                <CardContent>
                  <Typography gutterBottom variant="h6">
                    {translate(`resources.${resourceConfig.name}.searchBySerialNumber`)}
                  </Typography>
                  {gtinEmpty && (
                    <Typography variant="body2" color="textSecondary" component="p">
                      {translate(`resources.${resourceConfig.name}.enterGtin`)}
                    </Typography>
                  )}
                  {!gtinEmpty && searchingPrintJournalElement && (
                    <Typography variant="body2" color="textSecondary" component="p">
                      {translate(`resources.${resourceConfig.name}.searching`)}
                    </Typography>
                  )}
                  {!gtinEmpty &&
                    !searchingPrintJournalElement &&
                    existPrintJournalElement === null && (
                      <Typography variant="body2" color="textSecondary" component="p">
                        {translate(`resources.${resourceConfig.name}.itemNotFound`)}
                      </Typography>
                    )}
                  {!gtinEmpty &&
                    !searchingPrintJournalElement &&
                    existPrintJournalElement !== null && (
                      <Typography variant="body2" color="textSecondary" component="p">
                        {translate(`resources.${resourceConfig.name}.alreadyExistText`)} (#
                        {existPrintJournalElement.id})
                      </Typography>
                    )}
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    onClick={this.onSubmitExist}
                    disabled={
                      this.state.processing ||
                      gtinEmpty ||
                      searchingPrintJournalElement ||
                      existPrintJournalElement === null
                    }>
                    {translate(`resources.${resourceConfig.name}.alreadyExistAction`)}
                  </Button>
                  {this.state.processing && <CircularProgress size={20} />}
                </CardActions>
              </Card>
            )}
            {/* {this.state.existPrintJournalElement !== null && (
              <Card style={styles.card} fullWidth>
                <CardContent>
                  <Typography gutterBottom variant="h6">
                    {translate(`resources.${resourceConfig.name}.alreadyExistTitle`)}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {translate(`resources.${resourceConfig.name}.alreadyExistText`)}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    onClick={this.onSubmitExist}
                    disable={this.state.processing}
                  >
                    {translate(`resources.${resourceConfig.name}.alreadyExistAction`)}
                  </Button>
                  {this.state.processing && (
                    <CircularProgress size={20} />
                  )}
                </CardActions>
              </Card>
            )} */}

            <TextInput
              source={'ordernum'}
              disabled={this.state.searchingPrintJournalElement}
              fullWidth
              label={translate(`resources.${resourceConfig.name}.fields.ordernum`)}
            />
            <Field
              render={({ input }) =>
                // TODO: переделать на изменение формы при выборе чекбокса. Временный фикс
                input.value.importPrint ? (
                  <NumberInput
                    source={'menge'}
                    disabled={true}
                    fullWidth
                    label={`resources.${resourceConfig.name}.fields.menge`}
                  />
                ) : (
                  <NumberInput
                    source={'menge'}
                    disabled={false}
                    fullWidth
                    label={`resources.${resourceConfig.name}.fields.menge`}
                  />
                )
              }
            />
            {templateNames.length === 0 ? (
              <TextInput
                source={'label'}
                disabled={this.state.searchingPrintJournalElement}
                fullWidth
                label={translate(`resources.${resourceConfig.name}.fields.label`)}
              />
            ) : (
              <SelectInput
                fullWidth
                source={'label'}
                disabled={this.state.searchingPrintJournalElement}
                choices={templateNames}
                optionText="name"
                optionValue="name"
                label={translate(`resources.${resourceConfig.name}.fields.label`)}
              />
            )}
            <Field
              render={({ input }) =>
                // TODO: переделать на изменение формы при выборе чекбокса. Временный фикс
                input.value.importPrint ? (
                  <TextInput
                    source={'emissionType'}
                    value={'FOREIGN'}
                    disabled
                    fullWidth
                    label={translate(`resources.${resourceConfig.name}.fields.emissionType`)}
                  />
                ) : (
                  <emissionType.Input
                    source={'emissionType'}
                    disabled={this.state.searchingPrintJournalElement}
                    label={translate(`resources.${resourceConfig.name}.fields.emissionType`)}
                    fullWidth
                  />
                )
              }
            />
            <mrkStatusSystem.Input
              source={'mrkStatusAfterPrint'}
              fdisabled={this.state.searchingPrintJournalElement}
              fullWidth
              label={translate(`resources.${resourceConfig.name}.fields.mrkStatusAfterPrint`)}
            />
            <Field
              render={({ input }) =>
                // TODO: переделать на изменение формы при выборе чекбокса. Временный фикс
                input.value.importPrint ? (
                  <TextInput
                    source={'ean'}
                    disabled={this.state.searchingPrintJournalElement}
                    fullWidth
                    label={translate(`resources.${resourceConfig.name}.fields.ean`)}
                    autoComplete="off"
                    autoCorrect="off"
                    spellCheck="off"
                    validate={[required()]}
                  />
                ) : (
                  <TextInput
                    source={'ean'}
                    disabled={this.state.searchingPrintJournalElement}
                    fullWidth
                    label={translate(`resources.${resourceConfig.name}.fields.ean`)}
                    autoComplete="off"
                    autoCorrect="off"
                    spellCheck="off"
                  />
                )
              }
            />
            <NumberInput
              source={'inputhId'}
              disabled={this.state.searchingPrintJournalElement}
              fullWidth
              label={translate(`resources.${resourceConfig.name}.fields.inputhId`)}
            />
            <BooleanInput
              source={'importPrint'}
              disabled={this.state.searchingPrintJournalElement}
              label={translate(`resources.${resourceConfig.name}.fields.importPrint`)}
            />
            <TextInput
              source={'itemCode'}
              disabled={this.state.searchingPrintJournalElement}
              fullWidth
              label={translate(`resources.${resourceConfig.name}.fields.itemCode`)}
            />
          </SimpleForm>
        </div>
      </Drawer>
    );
  }
}

export default compose(
  withRouter,
  connect(null, { showNotification }),
  translate,
  withStyles(styles),
)(AppModule);
