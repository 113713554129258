import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Print',
      title: 'Print',
      alreadyExistTitle: 'Found a similar entry',
      alreadyExistText: 'You can print this file',
      alreadyExistAction: 'Print for exist entry',
      findCount: 'Found items: %{count}',
      executeAction: 'Print',
      errorMessage: 'Error',
      errorMessageKMNotFound: 'Marking Code not found',
      warnFoundKMNotAll: 'Warning: found %{found} of the %{total} codes',
      successMessage: 'Request successfully sent',
      createAnotherAction: 'Create another',
      downloadAs: 'File format',
      asPdf: 'PDF',
      asCsv: 'CSV',
      searchBySerialNumber: 'Print by serial number',
      enterGtin: 'Enter gtin',
      searching: 'Searching...',
      itemNotFound: 'Item not found',

      canNotFoundEANOrGTIN: 'Can not found EAN or GTIN',
      theProductIsNotRegisteredInTheDeclaration: 'The product is not registered in the declaration',
      setGTINOrOrdernum: 'Set GTIN or Ordernum',
      incorrectLabelTemplate: 'Incorrect label template or label template path',
      notFoundArticleMapByItemCode: 'Not found GTIN by Item code',

      fields: {
        menge: 'Quantity',
        companyUniqName: 'Company system name',
        creatorUsername: 'Creator',
        gtin: 'GTIN',
        label: 'Template',
        matnr: 'Material',
        ean: 'EAN',
        inputhId: 'Introduce id',
        ordernum: 'Order number',
        serialNumber: 'Serial number',
        emissionType: 'Emission type',
        mrkStatusAfterPrint: 'Internal status',
        importPrint: 'Import print',
        itemCode: 'Item Code',
      },
    }
  }
}